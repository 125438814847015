.tile-container {
  composes: flex from global;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.collection-card-wrapper {
  composes: mr32 flex-start-top mb24 from global;
}

.collection-card-wrapper:nth-of-type(3n) {
  margin-right: 0;
}

.collections-row1 {
  composes: flex pt4 from global;
}

@media (--screen-tabletOrLower) {
  .tile-container {
    width: 100%;
  }

  .collection-card-wrapper {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .collection-card-wrapper:nth-of-type(3n) {
    margin-right: 16px;
  }

  .collections-row1::after {
    content: '';
    border-right: 1px solid transparent;
  }

  .collections-slider {
    composes: full-width from global;
    margin-left: -16px;
  }
}
