.tablet-container {
  width: 100%;
  margin: auto;
}

.header {
  composes: flex tablet-container-padded from global;
  width: 100%;
  justify-content: space-between;
}

.modal-container {
  composes: tablet-container from global;
  width: 100vw !important;
  background: var(--color-white);
  min-height: 100vh;
  left: 0;
  padding: 24px 0 84px 16px;
  z-index: 5;
}

.modal-title {
  text-align: left;
}

.modal-close {
  composes: text-20 mr16 from global;
  position: relative;
  right: 0;
  top: -3px;
  float: right;
  line-height: 1;
  vertical-align: text-top;
}

.modal-section {
  composes: mt24 from global;
  text-align: left;
}

.modal-section:first-of-type {
  margin-top: 32px;
}

.search-box {
  composes: btn btn-md text-light text-left full-width desktop-hidden from global;
  border: 1px solid var(--color-border);
}

@media (--screen-desktop) {
  .tablet-container {
    max-width: 1184px;
    padding: 0 32px;
  }
}

@media (--screen-tabletOrLower) {
  .tablet-container {
    max-width: 504px;
  }

  .discover-text {
    display: inline;
  }

  .header {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 16px;
  }
}
