.all-container {
  composes: tablet-container from global;
  width: 1120px;
  margin: 0 auto;
  min-height: calc(100vh - 136px);
}

.sc-container {
  composes: flex ml24 from global;
  width: 100%;
  min-height: 700px;
  flex-wrap: wrap;
  flex-direction: column;
}

.all-smallcase-body {
  composes: flex from global;
}

.dd-container {
  position: relative;
  width: 100%;
}

.dd-row {
  position: absolute;
  bottom: 16px;
  right: 0;
}

.empty-state {
  display: flex;
}

.empty-text {
  margin-top: 64px;
  margin-left: 48px;
  width: 300px;
  line-height: 1.38;
}

.smallcasecard-link {
  width: 100%;
}

@media (--screen-tabletOrLower) {
  .all-container {
    width: 100%;
  }

  .sc-container {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .discovercard-link {
    width: 100%;
  }

  .smallcase-card {
    padding-left: 16px;
    padding-right: 16px;
  }

  .empty-state {
    display: block;
  }

  .empty-state > * {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0 16px;
  }

  .clear-button {
    color: var(--color-blue);
    cursor: pointer;
  }
}

.floatPositionExploreAll {
  transform: translateY(75px);
  z-index: 4;
}

.floatingAppButtonVisibleExplore {
  transform: translateY(-75px);
}
