.tab {
  float: left;
}

.tab_border {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-border);
}

.tab_element {
  composes: inline-block font-regular text-16 text-normal from global;
  margin-right: 32px;
  padding-bottom: 16px;
  cursor: pointer;
}

.tab_element:hover {
  color: var(--color-blue);
}

.tab_active {
  color: var(--color-blue) !important;
  position: relative;
}

.tab_active:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--color-blue);
  bottom: -1px;
  z-index: 3;
}

@media (--screen-tabletOrLower) {
  .tab_border {
    padding: 0 16px;
    margin-bottom: 0;
  }
}
