.callout {
  composes: flex-start-top from global;
  width: 544px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
}

.callout-large {
  composes: flex mb32 from global;
  flex-direction: column;
  justify-content: center;
  background-image: url(/public/static/images/svg/discover-callout.svg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 196%;
}

.img {
  height: 172px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.text {
  margin-left: 8px;
}

.title {
  composes: text-18 font-medium text-dark lh-138 mt32 mr32 from global;
}

@media (--screen-tabletOrLower) {
  .callout {
    width: 100%;
    margin: 0 auto;
  }

  .callout-large {
    width: 100%;
    margin: 0 auto 32px;
  }

  .callout-full-width {
    width: 100%;
  }

  .img {
    height: 75px;
    align-self: flex-end;
  }

  .img-large {
    height: 134px;
    margin: 8px auto 0;
  }

  .title {
    font-size: 14px;
    margin-top: 0;
    margin-right: 0;
  }

  .title-large {
    font-size: 16px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 12px;
  }
}
