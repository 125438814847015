.title-text {
  margin-left: 16px;
}

.cta-link {
  composes: text-center block mt24 pt8 pb8 pl8 pr8 pointer from global;
  width: 97.5%;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.cta-container {
  composes: pt16 pb16 pl8 pr8 from global;
}

.cta-container:hover {
  background-color: var(--banner-bg);
  border-radius: 8px;
}

.cta-text {
  composes: text-blue text-14 font-medium from global;
}

@media (--screen-tabletOrLower) {
  .slider {
    margin-left: -16px;
  }
}
