.container {
  composes: mb32 tablet-container-padded full-width br-4 from global;
  padding: 64px 56px;
  background-color: var(--banner-bg);
}

.content-container {
  composes: flex from global;
  align-items: center;
}

.media-image {
  composes: inline-block br-4 from global;
  height: auto;
  width: 308px;
}

.text-container {
  composes: inline-block vertical-top from global;
  width: calc(100% - 404px);
}

.user-info-container {
  composes: inline-block from global;
}

.user-avatar {
  composes: img-32 inline-block mr12 from global;
  border-radius: 50%;
  background-position: center;
}

.user-info {
  composes: flex from global;
  align-items: center;
}

.image-link {
  composes: relative inline-block from global;
  height: auto;
  width: auto;
  margin-right: 40px;
}

.figure-play-container {
  composes: flex absolute from global;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (--screen-desktop) {
  .header-anchor:hover h1 {
    color: var(--color-blue);
  }
}

@media (--screen-tabletOrLower) {
  .container {
    padding: 16px 0;
    margin: 24px 0 8px;
    border-radius: unset;
  }

  .image-link {
    margin-right: 0;
  }

  .media-image {
    width: calc(100vw - 32px);
    height: auto;
    margin-right: 0;
    margin-bottom: 12px;
    max-width: 472px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .text-container {
    width: 100%;
  }

  .user-avatar {
    display: none;
  }

  .user-info-container {
    display: block;
  }

  .user-info-container p {
    display: inline-block;
    margin-right: 12px;
  }
}
