.filters-container {
  composes: pb64 from global;
}

.filters-slider-container {
  composes: flex-start mt16 from global;
}

.filter {
  composes: mr16 from global;
}

@media (--screen-tabletOrLower) {
  .filter {
    width: 100%;
    margin-bottom: 8px;
    min-width: 244px;
  }

  .filters-slider-wrapper {
    composes: full-width from global;
    margin-left: -16px;
  }

  .filters-slider-container::after {
    content: '';
    border: 1px solid transparent;
  }
}
