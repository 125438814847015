.banner-container {
  position: relative;
  margin-bottom: 64px;
  overflow: hidden;
}

.banner {
  position: relative;
  height: 284px;
  padding: 48px 64px;
  margin-top: 16px;
  background-color: #2d7bde;
  border-radius: 4px;
  display: block;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.heading {
  composes: font-semibold from global;
  color: white;
  font-size: 24px;
  letter-spacing: -0.3px;
}

.sub-text {
  composes: text-16 lh-157 mt16 from global;
  max-width: 420px;
}

.icon {
  composes: icon text-24 from global;
  margin-right: 8px;
  color: white;
}

.icon:before {
  color: white;
}

.label {
  composes: font-medium from global;
  margin-right: 24px;
}

.label:last-child {
  margin-right: 0;
}

.awi-discover-image {
  position: absolute;
  bottom: 0;
  right: 122px;
  height: 250px;
}

@media (--screen-tabletOrLower) {
  .banner-container {
    margin-bottom: 24px;
  }

  .banner {
    padding: 16px;
    height: auto;
    width: 100%;
    margin-top: 0;
  }

  .sub-text {
    max-width: 230px;
    font-size: 14px;
    margin-top: 8px;
  }

  .label-wrapper {
    display: none;
  }

  .heading {
    font-size: 20px;
  }

  .awi-discover-image {
    right: 12px;
    height: 140px;
  }
}

@media (--screen-smaller) {
  .awi-discover-image {
    right: 12px;
    height: 95px;
  }
}
