.container {
  composes: pb16 mob-hidden relative from global;
  width: 270px;
  border-bottom: 1px solid var(--text-color-light);
}

.container:focus-within {
  border-bottom: 1px solid var(--color-blue);
}

.search-input {
  composes: text-14 font-regular text-light from global;
  border: 0;
  width: calc(100% - 24px);
}

.search-input::placeholder {
  color: var(--text-color-light);
}

.dropdown {
  composes: absolute from global;
  display: none;
  bottom: -67px;
  z-index: 3;
  width: 320px;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.07),
    0 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--color-white);
}

.search-input:focus ~ .dropdown {
  display: block;
}
