.title-text {
  composes: ml16 from global;
}

.collections-wrap {
  composes: flex-start mb32 from global;
}

.collections-rows-wrap {
  composes: flex from global;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (--screen-tabletOrLower) {
  .collections-wrap {
    margin-bottom: 32px;
  }
}
