.banner {
  background-color: #3c3a81;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  height: 232px;
}

.cta {
  composes: btn btn-secondary-blue btn-md btn-mw-unset pl16 pr16 mt16 from global;
}

.title {
  composes: text-24 font-medium from global;
}

.subtitle {
  composes: text-16 mt8 from global;
  max-width: 340px;
}

/* Canceling baked margin from shared component */
.banner-container > div {
  margin-bottom: 0;
}

@media (--screen-tabletOrLower) {
  .title {
    font-size: 20px;
    max-width: 265px;
  }

  .subtitle {
    white-space: unset;
    font-size: 14px;
  }

  .cta {
    margin-top: 0;
  }

  .banner {
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: 100%;
    height: unset;
    background-size: cover;
  }
}
