.wrapper {
  padding-top: 16px;
}

.grid-fold {
  composes: tablet-container-padded from global;
  width: calc(100% - 64px);
  max-width: 1120px;
  margin: 0 auto;
}

.fw-fold.first {
  margin-top: 0;
  padding-top: 0;
}

.slider-container {
  background-color: var(--banner-bg);
}

.fw-fold.featured {
  padding-bottom: 0;
}

.stock-search-container {
  position: relative;
  width: 100%;
}

.stock-search {
  position: absolute;
  right: 0;
  bottom: 32px;
}

.recently-viewed,
.most-popular {
  padding: 32px 0;
}

.discover-all-button {
  composes: btn btn-lg btn-secondary-blue full-width mb24 mt24 from global;
}

.card-wrap {
  flex: 50%;
}

.cards-container {
  composes: flex mb32 from global;
  flex-wrap: wrap;
  align-items: stretch;
}

.manager-card {
  composes: full-height from global;
}

@media (--screen-tabletOrLower) {
  .grid-fold {
    width: calc(100% - 32px);
    max-width: 1120px;
    margin: 0 auto;
    padding-top: 0;
  }

  .discover-all-button-wrapper {
    text-align: center;
  }

  .discover-all-button {
    margin-top: 0;
    margin-bottom: 64px;
  }

  .cards-container {
    margin-bottom: 0;
  }
}
