:global(.shine) {
  background: var(--background-grey);
  background-image: linear-gradient(
    to right,
    var(--background-grey) 0%,
    var(--banner-bg) 20%,
    var(--background-grey) 40%,
    var(--background-grey) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 200px;
  animation: placeholderShimmer 1s linear infinite forwards;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.container {
  composes: tablet-container from global;
  width: 1120px;
  margin: 0 auto;
  min-height: calc(100vh - 136px);
}

.search-container {
  position: relative;
  width: 100%;
}

.discover-cards-container {
  composes: flex-apart mb64 full-width from global;
  flex-wrap: wrap;
}

.blog-content {
  composes: shine mb24 br-4 full-width from global;
  height: 320px;
}

.managers-container {
  composes: flex ml24 mt24 from global;
  justify-content: space-between;
  align-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.managers-card-wrapper {
  composes: mb24 from global;
}

@media (--screen-tabletOrLower) {
  .managers-container {
    margin: 0;
    display: flex;
    align-content: normal;
    flex-direction: column;
  }

  .managers-card-wrapper {
    margin-bottom: 0;
  }
}
