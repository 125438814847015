.container {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 16px;
  right: 0;
}

@media (--screen-tabletOrLower) {
  .container {
    display: none;
  }
}
