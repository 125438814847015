.filter-tags {
  composes: inline-block mr16 pointer pb8 pt8 pl12 pr12 br-4 from global;
  border: solid 1px var(--color-border);
}

.filter-tags:hover {
  text-decoration: line-through;
  background-color: var(--banner-bg);
}

.clear-all {
  composes: text-13 pointer text-light from global;
}

.clear-all:hover {
  color: var(--color-red);
}
